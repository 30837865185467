require('../less/hfgot.less');

var sheet = (function () {
	var style = document.createElement("style");
	style.appendChild(document.createTextNode(""));
	document.head.appendChild(style);
	return style.sheet;
})();

var esc = function (ev) {
	if (ev.keyCode == 27) {
		rm_overlay(ev);
		document.removeEventListener('keydown', esc);
	}
}

var add_overlay = function (title, data, container) {
	var overlay = document.createElement('div');
	overlay.setAttribute('id', 'hfg-punkt-ueberlagerung');
	container.appendChild(overlay);
	setTimeout(function() {
		overlay.className += ' aktiv';
	}, 5);

	var link = document.createElement('a');
	link.setAttribute('href', '#');
	link.setAttribute('id', 'hfg-depunkt');
	link.innerHTML = '\u2022 trigger the menu \u2022';
	overlay.appendChild(link);

	link.addEventListener('click', function(ev) {
		ev.preventDefault();
		rm_overlay(container);
	}); 

	// add the logo to the overlay
	var logo_container = document.createElement('div');
	logo_container.setAttribute('id', 'hfg-punkt-logo-container');
	logo_container.className += ' logo-container';
	var text = document.createElement('span');
	text.className += 'text';
	text.innerHTML = title;
	logo_container.appendChild(text);
	overlay.appendChild(logo_container);

	// add the buttons to the overlay
	var buttons_container = document.createElement('div');
	buttons_container.className += ' buttons-container';
	var buttons = document.createElement('ul');
	buttons.className += ' buttons list ' + 'count-' + data.length;

	// style relevant stuff
	var buttons_count = data.length;
	var full_bucket2 = Math.ceil(buttons_count/2);
	var full_bucket3 = Math.ceil(buttons_count/3);
	var last_bucket2 = buttons_count - full_bucket2;
	var last_bucket3 = buttons_count - 2*full_bucket3;
	var document_height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
	var document_height_correction = Math.max(document.getElementById('hfg-punkt-logo-container').clientHeight || 0);
	var container_height = document_height - document_height_correction;

	data.forEach(function(el, eli) {
		var li = document.createElement('li');
		li.className += ' button-container';
		if (eli > full_bucket2-1) {
			li.className += ' resize-2';
		}
		if (eli > 2*full_bucket3-1) {
			li.className += ' resize-3';
		}
		var a = document.createElement('a');
		a.setAttribute('href', el.path);
		a.className += ' button';
		var span = document.createElement('span');
		span.className += ' button-text';
		span.innerHTML = el.label;

		a.appendChild(span);
		li.appendChild(a);
		buttons.appendChild(li);
	});

	sheet.insertRule("@media (min-width: 840px) and (max-width: 1600px) {" +
		"#hfg-punkt-huelle #hfg-punkt-ueberlagerung .buttons-container {" +
			"min-height: " + container_height + "px;" +
		"}" +
		"#hfg-punkt-huelle #hfg-punkt-ueberlagerung .buttons-container .buttons .button-container .button {" +
			"height: " + container_height/full_bucket2 + "px;" +
			"max-height: " + container_height/full_bucket2 + "px;" +
		"}" +
		"#hfg-punkt-huelle #hfg-punkt-ueberlagerung .buttons-container .buttons .button-container.resize-2 .button {" +
			"height: " + container_height/last_bucket2 + "px;" +
			"max-height: " + container_height/last_bucket2 + "px;" +
			"min-height: " + 130*full_bucket2/last_bucket2 + "px;" +
		"}" +
	"}", 0);

	sheet.insertRule("@media (min-width: 1600px) {" +
		"#hfg-punkt-huelle #hfg-punkt-ueberlagerung .buttons-container {" +
			"min-height: " + container_height + "px;" +
		"}" +
		"#hfg-punkt-huelle #hfg-punkt-ueberlagerung .buttons-container .buttons .button-container .button {" +
			"height: " + container_height/full_bucket3 + "px;" +
			"max-height: " + container_height/full_bucket3 + "px;" +
		"}" +
		"#hfg-punkt-huelle #hfg-punkt-ueberlagerung .buttons-container .buttons .button-container.resize-3 .button {" +
			"height: " + container_height/last_bucket3 + "px;" +
			"max-height: " + container_height/last_bucket3 + "px;" +
			"min-height: " + 130*full_bucket3/last_bucket3 + "px;" +
		"}" +
	"}", 0);
	
	buttons_container.appendChild(buttons);
	overlay.appendChild(buttons_container);

	document.documentElement.style.overflow = 'hidden';
	document.body.style.overflow = 'hidden';

}

var rm_overlay = function (ev) {
	var el = document.getElementById('hfg-punkt-ueberlagerung');
	el.classList.remove('aktiv');
	setTimeout(function() {
		el.remove();
		sheet.deleteRule(0);
		sheet.deleteRule(0);
	}, 250);
	document.documentElement.style.overflow = '';
	document.body.style.overflow = '';
}


var main = function (impl, title, data) {
	if (window != window.top) {
		return;
	}
	var container = document.createElement('div');
	container.setAttribute('id', 'hfg-punkt-huelle');
	container.className += impl;
	var link = document.createElement('a');
	link.setAttribute('href', '#');
	link.setAttribute('id', 'hfg-punkt');
	link.innerHTML = '\u2022 trigger the menu \u2022';
	document.body.appendChild(container);
	container.appendChild(link);

	document.addEventListener('keydown', esc, false);
	link.addEventListener('click', function(ev) {
		ev.preventDefault();
		add_overlay(title, data, container);
		document.addEventListener('keydown', esc, false);
	}); 
}

module.exports.main = main;

