var main = require('./app').main;

var type = 'io';

var description = 'Microsites HfG Karlsruhe';

var menu = [
	{ path: "http://adsz.hfg-karlsruhe.de/",                 label: "Ausstellungsdesign und Szenografie" },
	{ path: "http://kommunikationsdesign.hfg-karlsruhe.de/", label: "Kommunikationsdesign" },
	{ path: "http://infoart.hfg-karlsruhe.de/",              label: "Postdigitale Narrativität" },
	{ path: "http://film.hfg-karlsruhe.de/",                 label: "MK Film" },
	{ path: "http://fotografie.hfg-karlsruhe.de/",           label: "MK Fotografie" },
	{ path: "https://www.researchcatalogue.net/view/1910784/1910785", label: "MK Sound" },
	{ path: "http://infoart.hfg-karlsruhe.de/gamelab",       label: "Gamelab" },
];

window.onload = function(){main(type, description, menu)};
